@import '/styles/variables.scss';

.notFoundContainer {
    margin-top: 150px;
    margin-bottom: 150px;

    .notFoundContent {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .notFoundDescription {
            font-family: Formular, Arial, Helvetica, sans-serif;
            font-size: 18px;
            line-height: 36px;
            margin-bottom: 50px;
        }
    }
}
